import React, { ChangeEventHandler, FC, useState } from 'react';

import * as O from 'fp-ts/Option';

import { PasswordsFormBody, passwordsSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Anchor, Button, Checkbox, Group, PasswordInput, Text, Title } from '@mantine/core';
import config from '@root/config';

interface PasswordsFormProps {
  type: 'reset-password' | 'activation';
  loading: boolean;
  error: O.Option<string>;
  onSubmit: (params: PasswordsFormBody) => void;
}

const PasswordsForm: FC<PasswordsFormProps> = ({ type, loading, error, onSubmit }) => {
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);

  const [privacyError, setPrivacyError] = useState<boolean>(false);

  const handleSubmitForm = (params: PasswordsFormBody) => {
    if (type === 'activation' && !privacyAccepted) {
      setPrivacyError(true);
    } else {
      onSubmit(params);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordsFormBody>({
    mode: 'onChange',
    resolver: zodResolver(passwordsSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const handlePrivacyChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;

    setPrivacyError(false);

    setPrivacyAccepted(checked ?? false);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {error}
          </Text>
        </Alert>
      ))}

      {privacyError ? (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            Veuillez accepter les Conditions Générales d'Utilisation
          </Text>
        </Alert>
      ) : null}

      <Title size="h3" mb="md">
        Choisir un mot de passe
      </Title>

      <PasswordInput
        id="password"
        mt="sm"
        error={!!errors.password}
        placeholder="Entrez votre mot de passe"
        label="Mot de passe"
        required
        {...register('password')}
      />

      <PasswordInput
        id="confirmPassword"
        mt="lg"
        error={errors.confirmPassword?.message}
        placeholder="Confirmer le mot de passe"
        label="Confirmer le mot de passe"
        required
        {...register('confirmPassword')}
      />

      {type === 'activation' ? (
        <Checkbox
          mt={30}
          label={
            <Text>
              Accepter les{' '}
              <Anchor
                href={config.VITE_CGU_URL}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                weight="600"
                color="dark"
                sx={{ textDecoration: 'underline' }}
              >
                Conditions Générales d'Utilisation
              </Anchor>
            </Text>
          }
          checked={privacyAccepted}
          onChange={handlePrivacyChange}
        />
      ) : null}

      <Group mt={45} position="center">
        <Button type="submit" loading={loading} disabled={loading} radius={32} size="lg">
          {type === 'activation' ? 'Activer mon compte' : 'Valider'}
        </Button>
      </Group>
    </form>
  );
};

export default PasswordsForm;
