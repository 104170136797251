import { z } from 'zod';
import { Newtype } from 'newtype-ts';
import { emailZodSchema } from '@shared/schemas/email';

export type ResetPasswordToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;
export type ActivationToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;

export interface Profile {
  email: string;
  firstName: string | null;
  lastName: string | null;
  authorizations: Authorizations;
}

export interface Authorizations {
  alerts: boolean;
}
export const passwordInputSchema = z
  .string()
  .min(12, 'Le mot de passe doit contenir au moins 12 caractères.')
  .regex(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule.')
  .regex(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule.')
  .regex(/\d/, 'Le mot de passe doit contenir au moins un chiffre.')
  .regex(/[@$!%*?&]/, 'Le mot de passe doit contenir au moins un caractère spécial (@, $, !, %, *, ?, &).');
export const authenticateSchema = z.object({
  email: emailZodSchema.min(1),
  password: z.string().min(1),
});

export type AuthenticateParams = z.infer<typeof authenticateSchema>;

export const forgotPasswordSchema = z.object({
  email: emailZodSchema.min(1),
});

export type ForgotPasswordParams = z.infer<typeof forgotPasswordSchema>;

export const passwordsSchema = z
  .object({
    password: passwordInputSchema,
    confirmPassword: z.string().min(1, 'Champs requis'),
  })
  .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Les mots de passe ne correspondent pas',
  });

export type PasswordsFormBody = z.infer<typeof passwordsSchema>;
export type ResetPasswordParams = Pick<PasswordsFormBody, 'password'>;

export interface ActivationDetail {
  email: string;
}

export type ActivationParams = Pick<PasswordsFormBody, 'password'>;

export const updateProfileSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

export type UpdateProfileParams = z.infer<typeof updateProfileSchema>;
